<template>
  <iframe id='pptiframe'  allowfullscreen='true' class="iframes" :src="iframeWinPaht" frameborder="0" ref="iframes" />
  <!-- <iframe
    allowfullscreen='true'
    class="iframes"
    src="http://localhost:8080/ppt/#/look"
    frameborder="0"
    id='pptiframe'
    ref="iframes" /> -->
</template>
<script>
import { listAttachment } from "@/apiteacher/teacher";
import { taskList } from "@/apiteacher/teacher";
import {getVideoUrl} from '@/api/video/videomanage'

export default {
    props: {
    content:{
      type: String,
      default:()=>('')
    }
  },
  data() {
    return {
      loading: null,
      iframeWinPaht: process.env.VUE_APP_PPT_IDE + "look",
      pptData: {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem('pptToken'),
        platform: 'merchant', //id 1	merchant	可达鸭商户平台 id 2	tms	可达鸭平台
        currentTaskId: "",
        resourceId: "",
        courseId: "",
        courseName: "",
        unitId: "",
        unitName: "",
        sort: 0,
        taskList: [], //编辑时可为空
      },
      taskUseList:[],
    };
  },
  mounted() {
    this.$nextTick(()=>{
      window.addEventListener("message", this.handleMessage, true);
      let loginName = localStorage.getItem('userName')
      this.$watermark.setCourseWare('可达鸭编程-'+loginName);
    })

  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage, true);
  },
  async created() {
    const res = await taskList({
      courseUnitId: this.$route.query.ui,
    });
    this.pptData = {
      ...this.pptData,
      currentTaskId: '',
      resourceId: '',
      courseId: this.$route.query.ci,
      courseName: this.$route.query.cn,
      unitId: this.$route.query.ui,
      unitName: this.$route.query.un,
      content:this.$route.query.content,
      sort: 0,
      code: '可达鸭编程-'+localStorage.getItem('userName')+'|'+localStorage.getItem('pptToken'),
    };
    res.body.forEach((element,index) => {
      this.listAttachment(element,index).then(res=>{
        if(res.content){
          this.taskUseList.push({
            taskId: res.id,
            taskName: res.title,
            resourceId: res.content,
          })
        }
      })
    });
  },

  methods: {
    async listAttachment (element,index){
      let info = await listAttachment({
        taskId: element.id,
        type: "课件",
      })
      if(info.body && info.body.length>0){
        return {...element,content:info.body[0].content,index}
      }else{
        return {...element,content:'',index}
      }
    },

    changeSlidesPage(taskId){
      let pptDom = document.getElementById('pptiframe').contentWindow
      let currentTask = this.taskUseList.find(i=>i.taskId===taskId)
      if(currentTask){
        pptDom.postMessage({ data:{
          taskId: currentTask.resourceId
        }, type: "changeSlidesPage" }, "*");
      }
    },
    handleMessage(e) {
      let pptDom = document.getElementById('pptiframe').contentWindow
      switch (e.data.type) {
        case "setUseWareId":
          this.$emit("setUseWareId",e.data.data.id);
          break;
        case "getData":
          this.pptData.taskList = this.taskUseList;
          pptDom.postMessage({ data: this.pptData, type: "initData" }, "*");
          break;
        case "currentTaskId":
          this.$emit('changeTaskByChild',e.data.data.taskId)
          break;
        case "getPptVideoInfo":
           getVideoUrl({fileId:e.data.data.id.split('-')[1]}).then(res=>{
              pptDom.postMessage(
            { data: {
              ...res,
              id:e.data.data.id,
              mode:'look',
              code: '可达鸭编程-'+localStorage.getItem('userName')+'|'+localStorage.getItem('pptToken'),
              }, type: "returnPptVideoInfo" },
            "*"
            );
           })
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang='scss'>
.iframes {
  width: 100%;
  height: 100%;
  min-height: 600px;
  overflow: hidden;
}
#app {
  overflow: hidden;
}
</style>
